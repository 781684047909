import { useState } from "react"
import { DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { RHFUploadSingleFile } from "minimals-template/components/hook-form"
import { Bound } from "lib/@components/binding/Bound"
import { useRefresh } from "lib/@hooks/useRefresh"
import { OkCancel } from "lib/@components/okCancel"

export function FolderName({ title = "New Group", label = "Group Name" }) {
    const [name, setName] = useState("")
    const [data] = useState({})
    const refresh = useRefresh()

    return (
        <Bound target={data} refresh={refresh}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Stack spacing={1} sx={{ mt: 2 }}>
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        value={name}
                        onChange={setFromEvent(setName)}
                        label={label}
                        data-cy="folder-name-textfield"
                    />
                    <RHFUploadSingleFile refresh={refresh} name="image" />
                </Stack>
            </DialogContent>
            <OkCancel value={{ name, image: data?.image }} />
        </Bound>
    )
}
