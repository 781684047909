import { useSearchParam } from "lib/@hooks/use-search-param"
import { Typography } from "@mui/material"

export function VersionInfo() {
    const [version] = useSearchParam("version")
    if (!version) return null

    return (
        <Typography
            component="div"
            sx={{ alignSelf: "flex-end", pb: 0.5, pr: 2 }}
            variant="body2"
            data-cy="version-info"
        >
            v {version}
        </Typography>
    )
}
