import { Button } from "@mui/material"
import { UpdateCompanyDetailsDialog } from "./update-company-details-dialog"
import { useDialog } from "lib/@hooks/useDialog"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { updateSystemClientFubspot } from "routes/sfg20-admin/plugins/companies-tab/tabs/profile/controller/updateSystemClientFubspot.api"
import { errorSnackbar } from "lib/snackbar/error-snackbar"

export function UpdateCompanyDetails() {
    const { client } = useBoundContext()
    const updateCompanyDialog = useDialog(<UpdateCompanyDetailsDialog />)
    return (
        <Button variant="contained" onClick={handleUpdate} sx={{ mt: 2 }}>
            Edit Company Details
        </Button>
    )

    async function handleUpdate() {
        const updateCompany = await updateCompanyDialog({ client })
        try {
            if (updateCompany) {
                await updateSystemClientFubspot(updateCompany)
            }
        } catch (e) {
            errorSnackbar("Failed to update company")
        }
    }
}
