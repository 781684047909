import { Box, Card, CardContent, DialogContent, DialogTitle, IconButton, InputAdornment, Stack } from "@mui/material"
import { BoundTextField } from "lib/@components/binding/bound-components"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { DataInputDialog } from "lib/@dialogs/data-input-dialog"
import Iconify from "minimals-template/components/Iconify"

export function UpdateCompanyDetailsDialog({ client }) {
    return (
        <DataInputDialog okLabel="Update" okCaption="Update Details" initialValue={client}>
            <DialogTitle>Update Company Details</DialogTitle>
            <DialogContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Card>
                        <CardContent>
                            <Stack spacing={3}>
                                <BoundTextField
                                    field="name"
                                    label="Company Name"
                                    data-cy="fubspot-company-name-input"
                                    yup="string.required"
                                />
                                <BoundTextField
                                    field="address"
                                    label="Street Address"
                                    data-cy="fubspot-company-address-input"
                                />
                                <Stack spacing={3} direction="row">
                                    <BoundTextField field="city" data-cy="fubspot-company-city-input" />
                                    <BoundTextField field="postcode" data-cy="fubspot-company-postcode-input" />
                                </Stack>
                                <Stack spacing={3} direction="row">
                                    <BoundTextField
                                        field="phone"
                                        label="Phone Number"
                                        data-cy="fubspot-company-phone-input"
                                    />
                                    <BoundTextField
                                        field="email"
                                        label="Email Address"
                                        data-cy="fubspot-company-email-input"
                                    />
                                </Stack>
                                <WebsiteAddress />
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </DialogContent>
        </DataInputDialog>
    )
}

function WebsiteAddress() {
    const [website] = useBoundValue("website")

    return (
        <BoundTextField
            field="website"
            label="Website Address"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => openWebsite(website)}>
                            <Iconify icon="ic:round-open-in-new" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            data-cy="fubspot-company-website-input"
        />
    )

    function openWebsite(website) {
        const hasProtocol = /^(http:\/\/|https:\/\/)/i.test(website)
        const finalURL = hasProtocol ? website : `http://${website}`
        window.open(finalURL, "_blank", "noopener")
    }
}
