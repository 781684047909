import { appRoute } from "routes/app/home.runtime"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { AssetRegisters } from "routes/asset-register/asset-registers"
import { AssetRegister } from "routes/asset-register/asset-register"
import { Asset } from "routes/asset-register/asset"
import { checkPermission } from "library/authorization"
import { and } from "lib/logic"
import { getDemandForFeature } from "lib/features"
import { FEATURE_FLAG_ASSET_MAPPING } from "./feature-flag-asset-mapping"
import DemandGuard from "minimals-template/components/guards/DemandGuard"

appRoute.register(
    "assets",
    <DemandGuard demands={[`$${getDemandForFeature(FEATURE_FLAG_ASSET_MAPPING)}`, "$professional-services"]} and={true}>
        <AssetRegisters $lazy />
    </DemandGuard>
)
appRoute.register(
    "asset/:assetId",
    <DemandGuard demands={[`$${getDemandForFeature(FEATURE_FLAG_ASSET_MAPPING)}`, "$professional-services"]} and={true}>
        <Asset $lazy />
    </DemandGuard>
)
appRoute.register(
    "assets/:id",
    <DemandGuard demands={[`$${getDemandForFeature(FEATURE_FLAG_ASSET_MAPPING)}`, "$professional-services"]} and={true}>
        <AssetRegister $lazy />
    </DemandGuard>
)
appRoute.register(
    "assets/:id/:assetId",
    <DemandGuard demands={[`$${getDemandForFeature(FEATURE_FLAG_ASSET_MAPPING)}`, "$professional-services"]} and={true}>
        <Asset $lazy />
    </DemandGuard>
)

registerConditionalPageNavigation(
    and(
        () => checkPermission(`$${getDemandForFeature(FEATURE_FLAG_ASSET_MAPPING)}`),
        () => checkPermission("$professional-services")
    ),
    `/app/assets`,
    "Asset Registers",
    "healthicons:register-book",
    {
        priority: 153,
        group: "Planning",
        subPaths: true,
    }
)
