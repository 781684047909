import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import { PlanRoot } from "library/tree/constants"
import { getBasePlanId } from "routes/plan/controller/use-plan"
import { available } from "routes/regime/plugins/publishing/available"
import { Secure } from "lib/authorization/secure"
import { Frag } from "lib/@components/slot/frag"
import { If } from "lib/@components/switch"
import { Button } from "@mui/material"
import { confirm } from "lib/@dialogs/confirm"
import { publishPlan, removePlan } from "routes/plan/controller/publish-plan"
import { navigate } from "lib/routes/navigate"
import { LiveClient, PublishPortal } from "routes/regime/plugins/publishing/publishing.runtime"
import { useEffect, useState } from "react"
import { busyWhile } from "lib/@components/busy/busy-while"
import { useModelStats } from "routes/facilities/controller/use-model-stats"

export function PublishArea() {
    const { parentItem } = useBoundContext()
    const parent = parentFind(parentItem, (c) => c[PlanRoot])
    const id = getBasePlanId(parent)
    const isAvailable = available("ready")()
    const [isUnknown, setIsUnknown] = useState(false)

    const stats = useModelStats(parent.id)

    useEffect(() => {
        if (stats) {
            setIsUnknown(!!stats.periods?.find((t) => t.period === "Unknown")?.count > 0)
        }
    }, [stats])

    return (
        <>
            <PublishPortal width={1} />
            <Secure demand="publishRegime" hide fallback={<Frag />}>
                <If field="activeStep.props.id" equals="Candidate">
                    {isAvailable && (
                        <LiveClient>
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={publish}
                                sx={{ mx: "auto", display: "block", mt: 1 }}
                                data-cy="publish-candidate-to-live-btn"
                            >
                                Make Live
                            </Button>
                        </LiveClient>
                    )}
                </If>
            </Secure>
        </>
    )

    async function publish() {
        if (isUnknown) {
            if (
                await confirm(
                    `Your regime has 0U (Zero U - user-defined frequencies), which won't transfer to third-party software like CAFM.
                    
                    If you agree, choose "Ok" to transfer these tasks, select "Cancel", tailor the 0U schedules, and proceed to publish to Live. For details on Tailoring, refer to the Help Centre.`,
                    `Warning 0U's detected`
                )
            ) {
                await busyWhile(async () => {
                    await publishPlan(`ready|${id}`, "live")
                    await removePlan(id, "ready")
                    navigate(`/app/schedules?id=live|${id}`, { replace: true })
                }, "Make Live")
            }
        } else {
            if (await confirm("Are you sure you want to make the candidate version live?")) {
                await busyWhile(async () => {
                    await publishPlan(`ready|${id}`, "live")
                    await removePlan(id, "ready")
                    navigate(`/app/schedules?id=live|${id}`, { replace: true })
                }, "Make Live")
            }
        }
    }
}
